import React, { useEffect } from 'react'

interface ISpritesProps {

}

export const Sprites:React.FC<ISpritesProps> = () => {

    return (
        <svg viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg" style={{display: "none"}}>

            <symbol viewBox="0 0 16 15" id="plus">
                <path d="M14.5 8.41611H8.93478V14H7.06522V8.41611H1.5V6.58389H7.06522V1H8.93478V6.58389H14.5V8.41611Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 10 7" id="drop-down-arrow">
                <path d="M8.82812 0L10 1.17188L5 6.17188L0 1.17188L1.17188 0L5 3.82812L8.82812 0Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 17 20" id="notification">
                <path d="M9.88154 19.3897C9.50689 19.7966 9.03857 20 8.47658 20C7.94582 20 7.4775 19.7966 7.07163 19.3897C6.69697 19.0141 6.50964 18.5603 6.50964 18.0282H10.4904C10.4904 18.5603 10.2874 19.0141 9.88154 19.3897ZM14.9862 13.9906L17 16.0094V16.9953H0V16.0094L2.01377 13.9906V8.49765C2.01377 6.96401 2.48209 5.61815 3.41873 4.46009C4.35537 3.27074 5.54178 2.50391 6.97796 2.15962V1.50235C6.97796 1.06416 7.11846 0.704225 7.39945 0.422535C7.71166 0.140845 8.07071 0 8.47658 0C8.91368 0 9.27273 0.140845 9.55372 0.422535C9.83471 0.704225 9.97521 1.06416 9.97521 1.50235V2.15962C11.4426 2.50391 12.6446 3.27074 13.5813 4.46009C14.5179 5.61815 14.9862 6.96401 14.9862 8.49765V13.9906Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 20 20" id="round-check">
                <path d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM9.0026 14L16.0737 6.92893L14.6595 5.51472L9.0026 11.1716L6.17421 8.3431L4.75999 9.7574L9.0026 14Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 20 20" id="round-error">
                <path d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 8.5858L7.17157 5.75736L5.75736 7.17157L8.5858 10L5.75736 12.8284L7.17157 14.2426L10 11.4142L12.8284 14.2426L14.2426 12.8284L11.4142 10L14.2426 7.17157L12.8284 5.75736L10 8.5858Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 11 8" id="check">
                <path d="M3.25391 6.20703L9.46094 0L10.2539 0.820312L3.25391 7.82031L0 4.56641L0.820312 3.74609L3.25391 6.20703Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 7 10" id="arrow-prev">
                <path d="M7 1.17188L5.82813 -5.12243e-08L0.828125 5L5.82812 10L7 8.82812L3.17188 5L7 1.17188Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 7 10" id="arrow-next">
                <path d="M-3.8589e-07 1.17188L1.17187 -5.12243e-08L6.17187 5L1.17188 10L-5.12243e-08 8.82812L3.82812 5L-3.8589e-07 1.17188Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 15" id="search">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.75 1.5C3.85051 1.5 1.5 3.85051 1.5 6.75C1.5 9.6495 3.85051 12 6.75 12C8.16431 12 9.44801 11.4408 10.392 10.5313C10.4118 10.5056 10.4335 10.4808 10.4571 10.4572C10.4807 10.4336 10.5055 10.4119 10.5313 10.3921C11.4407 9.44806 12 8.16435 12 6.75C12 3.85051 9.6495 1.5 6.75 1.5ZM12.0239 10.9633C12.9476 9.80855 13.5 8.34378 13.5 6.75C13.5 3.02208 10.4779 0 6.75 0C3.02208 0 0 3.02208 0 6.75C0 10.4779 3.02208 13.5 6.75 13.5C8.34374 13.5 9.80848 12.9477 10.9632 12.024L13.7196 14.7803C14.0125 15.0732 14.4874 15.0732 14.7803 14.7803C15.0732 14.4874 15.0732 14.0126 14.7803 13.7197L12.0239 10.9633Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 16 17" id="download">
                <path d="M14.0317 5.93664L8.24656 11.6832L2.5 5.93664H5.77824V1H10.7149V5.93664H14.0317ZM2.5 13.3416H14.0317V15H2.5V13.3416Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 16 17" id="logout">
                <path d="M0.842105 17C0.377027 17 0 16.6195 0 16.15V0.85C0 0.380562 0.377027 0 0.842105 0H12.6316C13.0967 0 13.4737 0.380562 13.4737 0.85V3.4H11.7895V1.7H1.68421V15.3H11.7895V13.6H13.4737V16.15C13.4737 16.6195 13.0967 17 12.6316 17H0.842105ZM11.7895 11.9V9.35H5.89474V7.65H11.7895V5.1L16 8.5L11.7895 11.9Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 15" id="language">
                <path d="M7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15ZM5.78252 13.2506C5.06057 11.7194 4.61799 10.0307 4.52048 8.25H1.54642C1.8435 10.6324 3.53729 12.581 5.78252 13.2506ZM6.02302 8.25C6.13582 10.0791 6.65835 11.7973 7.5 13.314C8.34165 11.7973 8.86417 10.0791 8.97698 8.25H6.02302ZM13.4536 8.25H10.4795C10.382 10.0307 9.93945 11.7194 9.2175 13.2506C11.4627 12.581 13.1565 10.6324 13.4536 8.25ZM1.54642 6.75H4.52048C4.61799 4.96933 5.06057 3.28055 5.78252 1.74942C3.53729 2.419 1.8435 4.36762 1.54642 6.75ZM6.02302 6.75H8.97698C8.86417 4.92092 8.34165 3.20269 7.5 1.68599C6.65835 3.20269 6.13582 4.92092 6.02302 6.75ZM9.2175 1.74942C9.93945 3.28055 10.382 4.96933 10.4795 6.75H13.4536C13.1565 4.36762 11.4627 2.419 9.2175 1.74942Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 25 25" id="members">
                <path d="M19.4563 10.2181C18.8166 10.9407 18.0171 11.302 17.0576 11.302C16.0981 11.302 15.2807 10.9407 14.6055 10.2181C13.9659 9.49553 13.6461 8.63982 13.6461 7.65101C13.6461 6.62416 13.9659 5.76846 14.6055 5.08389C15.2807 4.3613 16.0981 4 17.0576 4C18.0171 4 18.8166 4.3613 19.4563 5.08389C20.1315 5.76846 20.4691 6.62416 20.4691 7.65101C20.4691 8.63982 20.1315 9.49553 19.4563 10.2181ZM10.3412 10.2181C9.70149 10.9407 8.90192 11.302 7.94243 11.302C7.01848 11.302 6.21891 10.9407 5.54371 10.2181C4.86851 9.49553 4.53092 8.63982 4.53092 7.65101C4.53092 6.62416 4.86851 5.76846 5.54371 5.08389C6.21891 4.3613 7.01848 4 7.94243 4C8.90192 4 9.70149 4.3613 10.3412 5.08389C11.0163 5.76846 11.3539 6.62416 11.3539 7.65101C11.3539 8.63982 11.0163 9.49553 10.3412 10.2181ZM4.69083 14.1544C5.89908 13.8501 6.98294 13.698 7.94243 13.698C8.93746 13.698 10.0213 13.8501 11.194 14.1544C12.3667 14.4586 13.4506 14.953 14.4456 15.6376C15.4407 16.3221 15.9382 17.1018 15.9382 17.9765V21H0V17.9765C0 17.1018 0.479744 16.3221 1.43923 15.6376C2.43426 14.953 3.51812 14.4586 4.69083 14.1544ZM17.0576 13.698C18.0526 13.698 19.1365 13.8501 20.3092 14.1544C21.4819 14.4586 22.548 14.953 23.5075 15.6376C24.5025 16.3221 25 17.1018 25 17.9765V21H18.177V17.9765C18.177 16.3412 17.4307 14.934 15.9382 13.755C16.4001 13.717 16.7733 13.698 17.0576 13.698Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 25 25" id="calendar-check">
                <path d="M18.3099 12.5878L10.7031 20.0234L6.63021 16.0422L8.00781 14.6956L10.7031 17.3302L16.9323 11.2412L18.3099 12.5878ZM21.4245 2.51756C22.1432 2.51756 22.7422 2.77127 23.2214 3.27869C23.7405 3.74707 24 4.33255 24 5.03513V22.4824C24 23.185 23.7405 23.79 23.2214 24.2974C22.7422 24.7658 22.1432 25 21.4245 25H3.57552C2.85677 25 2.23785 24.7658 1.71875 24.2974C1.23958 23.79 1 23.185 1 22.4824V5.03513C1 4.33255 1.23958 3.74707 1.71875 3.27869C2.23785 2.77127 2.85677 2.51756 3.57552 2.51756H4.83333V0H7.40885V2.51756H17.5911V0H20.1667V2.51756H21.4245ZM21.4245 22.4824V8.7822H3.57552V22.4824H21.4245Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="user">
                <path d="M9.12903 6.12281C8.54252 6.7076 7.82698 7 6.9824 7C6.16129 7 5.45748 6.7076 4.87097 6.12281C4.28446 5.53801 3.9912 4.83626 3.9912 4.01754C3.9912 3.17544 4.28446 2.46199 4.87097 1.87719C5.45748 1.2924 6.16129 1 6.9824 1C7.82698 1 8.54252 1.2924 9.12903 1.87719C9.71554 2.46199 10.0088 3.17544 10.0088 4.01754C10.0088 4.83626 9.71554 5.53801 9.12903 6.12281ZM4.55425 8.82456C5.44575 8.61404 6.25513 8.50877 6.9824 8.50877C7.73314 8.50877 8.55425 8.61404 9.44575 8.82456C10.3372 9.03509 11.1466 9.38596 11.8739 9.87719C12.6246 10.345 13 10.883 13 11.4912V13H1V11.4912C1 10.883 1.36364 10.345 2.09091 9.87719C2.84164 9.38596 3.66276 9.03509 4.55425 8.82456Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 11 15" id="sort-up-down">
                <path d="M8.64548 11.401H11L7.83612 14.5L4.70903 11.401H7.06355V5.93229H8.64548V11.401ZM3.12709 0.5L6.29097 3.59896H3.93645V9.06771H2.35452V3.59896H0L3.12709 0.5Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="position">
                <path d="M11.4621 6.20982C11.8876 6.20982 12.2455 6.36458 12.5357 6.67411C12.8452 6.96429 13 7.32217 13 7.74777C13 8.17336 12.8452 8.54092 12.5357 8.85045C12.2455 9.14062 11.8876 9.28571 11.4621 9.28571H10.5335V11.7812C10.5335 12.1101 10.4077 12.3906 10.1562 12.6228C9.92411 12.8743 9.63393 13 9.28571 13H6.93527V12.0714C6.93527 11.6071 6.77083 11.2202 6.44196 10.9107C6.1131 10.5818 5.71652 10.4174 5.25223 10.4174C4.78795 10.4174 4.39137 10.5818 4.0625 10.9107C3.75298 11.2202 3.59821 11.6071 3.59821 12.0714V13H1.24777C0.899554 13 0.599702 12.8743 0.348214 12.6228C0.116071 12.3906 0 12.1101 0 11.7812V9.4308H0.928571C1.39286 9.4308 1.78943 9.26637 2.1183 8.9375C2.44717 8.60863 2.61161 8.21205 2.61161 7.74777C2.61161 7.28348 2.44717 6.8869 2.1183 6.55804C1.78943 6.22917 1.39286 6.06473 0.928571 6.06473H0V3.71429C0 3.38542 0.116071 3.10491 0.348214 2.87277C0.599702 2.62128 0.899554 2.49554 1.24777 2.49554H3.71429V1.56696C3.71429 1.14137 3.85938 0.773809 4.14955 0.464286C4.45908 0.154762 4.82664 0 5.25223 0C5.67783 0 6.04539 0.154762 6.35491 0.464286C6.66443 0.773809 6.8192 1.14137 6.8192 1.56696V2.49554H9.28571C9.63393 2.49554 9.92411 2.62128 10.1562 2.87277C10.4077 3.10491 10.5335 3.38542 10.5335 3.71429V6.20982H11.4621Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="flag">
                <path d="M7.90938 1.53995H12V9.16804H6.87813L6.56875 7.66391H2.47813V13H1V0H7.6L7.90938 1.53995Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="email">
                <path d="M11.7916 2C12.1288 2 12.4098 2.11404 12.6347 2.34211C12.8782 2.55263 13 2.81579 13 3.13158V9.86842C13 10.1842 12.8782 10.4561 12.6347 10.6842C12.4098 10.8947 12.1288 11 11.7916 11H2.20843C1.87119 11 1.5808 10.8947 1.33724 10.6842C1.11241 10.4561 1 10.1842 1 9.86842V3.13158C1 2.81579 1.11241 2.55263 1.33724 2.34211C1.5808 2.11404 1.87119 2 2.20843 2H11.7916ZM11.7916 4.26316V3.13158L6.98595 5.94737L2.20843 3.13158V4.26316L6.98595 7.05263L11.7916 4.26316Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="settings">
                <path d="M11.4542 7.14084L12.8608 8.20892C13.0066 8.33099 13.0379 8.4734 12.9545 8.63615L11.6105 10.8944C11.5271 11.0571 11.3917 11.0978 11.2042 11.0164L9.54754 10.3756C9.17246 10.6401 8.79738 10.8537 8.42229 11.0164L8.17224 12.7254C8.1514 12.9085 8.03679 13 7.82841 13H5.17158C4.98404 13 4.86943 12.9085 4.82776 12.7254L4.5777 11.0164C4.18178 10.8537 3.8067 10.6401 3.45246 10.3756L1.79585 11.0164C1.60831 11.0775 1.47286 11.0368 1.38951 10.8944L0.0454645 8.63615C-0.0378871 8.4734 -0.00663024 8.33099 0.139235 8.20892L1.54579 7.14084C1.52495 6.89671 1.51454 6.6831 1.51454 6.5C1.51454 6.3169 1.52495 6.10329 1.54579 5.85915L0.139235 4.79108C-0.00663024 4.66901 -0.0378871 4.5266 0.0454645 4.36385L1.38951 2.10563C1.4937 1.94288 1.62914 1.90219 1.79585 1.98357L3.45246 2.62441C3.82754 2.35994 4.20262 2.14632 4.5777 1.98357L4.82776 0.274648C4.86943 0.0915493 4.98404 0 5.17158 0H7.82841C8.03679 0 8.1514 0.0915493 8.17224 0.274648L8.42229 1.98357C8.81821 2.14632 9.1933 2.35994 9.54754 2.62441L11.2042 1.98357C11.3917 1.92254 11.5271 1.96322 11.6105 2.10563L12.9545 4.36385C13.0379 4.5266 13.0066 4.66901 12.8608 4.79108L11.4542 5.85915C11.4959 6.10329 11.5167 6.3169 11.5167 6.5C11.5167 6.6831 11.4959 6.89671 11.4542 7.14084ZM4.85902 8.11737C5.31745 8.56495 5.85923 8.78873 6.48437 8.78873C7.13035 8.78873 7.68255 8.56495 8.14098 8.11737C8.59942 7.6698 8.82863 7.13067 8.82863 6.5C8.82863 5.86933 8.59942 5.3302 8.14098 4.88263C7.68255 4.43505 7.13035 4.21127 6.48437 4.21127C5.85923 4.21127 5.31745 4.43505 4.85902 4.88263C4.40058 5.3302 4.17137 5.86933 4.17137 6.5C4.17137 7.13067 4.40058 7.6698 4.85902 8.11737Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="tel">
                <path d="M2.59135 5.1875C3.64583 7.125 5.22756 8.59375 7.33654 9.59375L8.91827 8.125C9.12019 7.91667 9.36699 7.86458 9.65865 7.96875C10.4663 8.21875 11.3189 8.34375 12.2163 8.34375C12.3958 8.34375 12.5529 8.40625 12.6875 8.53125C12.8446 8.65625 12.9231 8.8125 12.9231 9V11.3438C12.9231 11.5104 12.8446 11.6667 12.6875 11.8125C12.5529 11.9375 12.3958 12 12.2163 12C9.99519 12 7.94231 11.5 6.05769 10.5C4.19551 9.47917 2.71474 8.10417 1.61538 6.375C0.538462 4.625 0 2.71875 0 0.65625C0 0.489583 0.0673077 0.34375 0.201923 0.21875C0.358974 0.0729167 0.527244 0 0.706731 0H3.23077C3.43269 0 3.60096 0.0729167 3.73558 0.21875C3.87019 0.34375 3.9375 0.489583 3.9375 0.65625C3.9375 1.48958 4.07211 2.28125 4.34135 3.03125C4.43109 3.30208 4.375 3.53125 4.17308 3.71875L2.59135 5.1875Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="calendar-selected">
                <path d="M9.77274 7.15457V10.4122H6.63333V7.15457H9.77274ZM9.12726 0H10.3889V1.30913H11.005C11.3571 1.30913 11.6505 1.44106 11.8852 1.70492C12.1395 1.94848 12.2667 2.25293 12.2667 2.61827V11.6909C12.2667 12.0562 12.1395 12.3708 11.8852 12.6347C11.6505 12.8782 11.3571 13 11.005 13H2.26163C1.90955 13 1.60637 12.8782 1.35208 12.6347C1.11736 12.3708 1 12.0562 1 11.6909V2.61827C1 2.25293 1.11736 1.94848 1.35208 1.70492C1.60637 1.44106 1.90955 1.30913 2.26163 1.30913H2.87778V0H4.13941V1.30913H9.12726V0ZM11.005 11.6909V4.56674H2.26163V11.6909H11.005Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 15" id="arrow-down">
                <path d="M8.65625 1V11.224L11.0625 8.65104L12 9.66667L8 14L4 9.66667L4.9375 8.65104L7.34375 11.224V1H8.65625Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 15" id="close">
                <path d="M11.5639 3.56382L8.13911 6.9886L11.5754 10.4249L10.4249 11.5754L6.9886 8.13911L3.56382 11.5639L2.4363 10.4364L5.86108 7.01159L2.4248 3.57532L3.57532 2.4248L7.01159 5.86108L10.4364 2.4363L11.5639 3.56382Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 16" id="edit">
                <path d="M11.5289 6.83609L3.86501 14.5H1V11.635L8.66391 3.97107L11.5289 6.83609ZM13.7851 4.57989L12.281 6.08402L9.41598 3.21901L10.9201 1.71488C11.0634 1.57163 11.2424 1.5 11.4573 1.5C11.6722 1.5 11.8512 1.57163 11.9945 1.71488L13.7851 3.50551C13.9284 3.64876 14 3.82782 14 4.0427C14 4.25758 13.9284 4.43664 13.7851 4.57989Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 16" id="trash">
                <path d="M1.85714 13.8333C1.85714 14.75 2.62857 15.5 3.57143 15.5H10.4286C11.3714 15.5 12.1429 14.75 12.1429 13.8333V3.83333H1.85714V13.8333ZM13 1.33333H10L9.14286 0.5H4.85714L4 1.33333H1V3H13V1.33333Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 16" id="sort-asc">
                <path d="M7.97076 1L13 6.06383H9.25731V15H6.74269V6.06383H3L7.97076 1Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 16" id="sort-desc">
                <path d="M8.02924 15L3 9.93617L6.74269 9.93617L6.74269 0.999999L9.25731 1L9.25731 9.93617L13 9.93617L8.02924 15Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="project">
                <path d="M13 4.55V12.3451C13 12.7082 12.7035 13 12.3377 13H1.66227C1.29663 13 1 12.7114 1 12.3553V0.64467C1 0.295952 1.29796 0 1.66551 0H8.33333V3.9C8.33333 4.25898 8.6318 4.55 9 4.55H13ZM13 3.25H9.66667V0.00206702L13 3.25ZM4.33333 3.25V4.55H6.33333V3.25H4.33333ZM4.33333 5.85V7.15H9.66667V5.85H4.33333ZM4.33333 8.45V9.75H9.66667V8.45H4.33333Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="comments">
                <path d="M12.3302 2.5939C12.5129 2.5939 12.6651 2.6651 12.7869 2.80751C12.929 2.92958 13 3.07199 13 3.23474V13L10.3817 10.4061H3.25761C3.07494 10.4061 2.91257 10.3451 2.77049 10.223C2.64871 10.0806 2.58782 9.92801 2.58782 9.76526V8.45305H11.0515V2.5939H12.3302ZM9.74239 6.5C9.74239 6.6831 9.67135 6.83568 9.52927 6.95775C9.40749 7.07981 9.26542 7.14084 9.10304 7.14084H2.58782L0 9.76526V0.640845C0 0.457746 0.0608899 0.305164 0.18267 0.183099C0.324746 0.0610329 0.476971 0 0.639344 0H9.10304C9.26542 0 9.40749 0.0610329 9.52927 0.183099C9.67135 0.305164 9.74239 0.457746 9.74239 0.640845V6.5Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 16 16" id="password-viewed">
                <path d="M8 1C11.866 1 15 4.134 15 8C15 11.866 11.866 15 8 15C4.134 15 1 11.866 1 8C1 4.134 4.134 1 8 1ZM8 2.4C4.9072 2.4 2.4 4.9072 2.4 8C2.4 11.0928 4.9072 13.6 8 13.6C11.0928 13.6 13.6 11.0928 13.6 8C13.6 4.9072 11.0928 2.4 8 2.4ZM8 4.5C9.93298 4.5 11.5 6.06701 11.5 8C11.5 9.93298 9.93298 11.5 8 11.5C6.06701 11.5 4.5 9.93298 4.5 8C4.5 7.64104 4.55403 7.29475 4.6544 6.96876C4.92823 7.57678 5.53969 8 6.25 8C7.21649 8 8 7.21649 8 6.25C8 5.53969 7.57678 4.92823 6.96883 4.65397C7.29475 4.55403 7.64104 4.5 8 4.5Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 15" id="password-hidden">
                <path d="M6.2814 12L5.0321 11.6597L5.54135 9.72814C4.77237 9.44026 4.06582 9.02118 3.44794 8.49773L2.05542 9.91303L1.14087 8.98352L2.5334 7.56822C1.76311 6.62905 1.22269 5.48826 1 4.23519L2.27272 4C2.76358 6.76201 5.14106 8.85706 7.99997 8.85706C10.8589 8.85706 13.2364 6.76201 13.7273 4L15 4.23519C14.7773 5.48826 14.2368 6.62905 13.4666 7.56822L14.8591 8.98352L13.9445 9.91303L12.552 8.49773C11.9341 9.02118 11.2276 9.44026 10.4586 9.72814L10.9679 11.6597L9.7186 12L9.20914 10.0676C8.81615 10.136 8.41217 10.1716 7.99997 10.1716C7.58784 10.1716 7.18379 10.136 6.79087 10.0676L6.2814 12Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 15" id="login">
                <path d="M13.8358 14C14.15 14 14.4179 13.8646 14.6397 13.5938C14.8799 13.3438 15 13.0313 15 12.6563L15 3.3125C15 2.95834 14.8799 2.64584 14.6397 2.375C14.4179 2.125 14.15 2 13.8358 2L3.1919 2C2.85928 2 2.57285 2.125 2.33262 2.375C2.11088 2.64583 2 2.95833 2 3.3125L2 6L3.1919 6L3.1919 3.3125L13.8358 3.3125L13.8358 12.6875L3.1919 12.6875L3.1919 10L2 10L2 12.6562C2 13.0312 2.11087 13.3438 2.33262 13.5938C2.57285 13.8646 2.85927 14 3.1919 14L13.8358 14ZM7.90405 5.34375L7.90405 7.34375L2 7.34375L2 8.65625L7.90405 8.65625L7.90405 10.6563L10.2878 8L7.90405 5.34375Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 25 25" id="timesheet">
                <path d="M1.25 1H23.75C24.4404 1 25 1.57209 25 2.27778V22.7222C25 23.4279 24.4404 24 23.75 24H1.25C0.55965 24 0 23.4279 0 22.7222V2.27778C0 1.57209 0.55965 1 1.25 1ZM3.75 6.11111V8.66667H6.25V6.11111H3.75ZM3.75 11.2222V13.7778H6.25V11.2222H3.75ZM3.75 16.3333V18.8889H21.25V16.3333H3.75ZM8.75 11.2222V13.7778H11.25V11.2222H8.75ZM8.75 6.11111V8.66667H11.25V6.11111H8.75ZM13.75 6.11111V8.66667H16.25V6.11111H13.75ZM18.75 6.11111V8.66667H21.25V6.11111H18.75ZM13.75 11.2222V13.7778H16.25V11.2222H13.75ZM18.75 11.2222V13.7778H21.25V11.2222H18.75Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 25 25" id="costs">
                <path d="M10.486 1.75525V14.514H23.2448C22.6602 20.4016 17.6926 25 11.6512 25C5.2164 25 0 19.7836 0 13.3489C0 7.30734 4.59834 2.33981 10.486 1.75525ZM12.8163 0C19.2867 0.557345 24.4427 5.71331 25 12.1837H12.8163V0Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 25 25" id="summary">
                <path d="M18.75 2.5H23.75C24.4404 2.5 25 3.05965 25 3.75V23.75C25 24.4404 24.4404 25 23.75 25H1.25C0.55965 25 0 24.4404 0 23.75V3.75C0 3.05965 0.55965 2.5 1.25 2.5H6.25V0H8.75V2.5H16.25V0H18.75V2.5ZM2.5 10V22.5H22.5V10H2.5ZM5 12.5H7.5V15H5V12.5ZM5 17.5H7.5V20H5V17.5ZM10 12.5H20V15H10V12.5ZM10 17.5H16.25V20H10V17.5Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="pin">
                <path d="M13 5.33333L12.037 6.22221L11.5556 5.77777L8.66664 8.44447L8.18517 10.6667L7.22221 11.5556L4.33333 8.88892L0.962967 12L0 11.1111L3.37037 8.00003L0.481483 5.33333L1.44444 4.44445L3.85185 4L6.74074 1.33333L6.25926 0.888894L7.22221 0L13 5.33333Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="time">
                <path d="M6.4935 0C2.9055 0 0 2.912 0 6.5C0 10.088 2.9055 13 6.4935 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.4935 0ZM8.6385 9.5615L5.85 6.7665V3.25H7.15V6.2335L9.5615 8.645L8.6385 9.5615Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 22 22" id="time-2">
                <path d="M10.99 1C5.47 1 1 5.48 1 11C1 16.52 5.47 21 10.99 21C16.52 21 21 16.52 21 11C21 5.48 16.52 1 10.99 1ZM14.29 15.71L10 11.41V6H12V10.59L15.71 14.3L14.29 15.71Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="hours">
                <path d="M9.256 3.744C8.4955 2.9835 7.501 2.6 6.5 2.6V6.5L3.744 9.256C5.265 10.777 7.735 10.777 9.2625 9.256C10.7835 7.735 10.7835 5.265 9.256 3.744ZM6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM6.5 11.7C3.627 11.7 1.3 9.373 1.3 6.5C1.3 3.627 3.627 1.3 6.5 1.3C9.373 1.3 11.7 3.627 11.7 6.5C11.7 9.373 9.373 11.7 6.5 11.7Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 13 13" id="total">
                <path d="M2 13V11.375L7.28125 6.5L2 1.625V0H11.75V2.4375H5.87969L10.2469 6.5L5.87969 10.5625H11.75V13H2Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 20 20" id="username">
                <path d="M18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.6418 18 13.1681 17.5054 14.4381 16.6571L15.5476 18.3214C13.9602 19.3818 12.0523 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10V11.5C20 13.433 18.433 15 16.5 15C15.2958 15 14.2336 14.3918 13.6038 13.4659C12.6942 14.4115 11.4158 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C11.1258 5 12.1647 5.37209 13.0005 6H15V11.5C15 12.3284 15.6716 13 16.5 13C17.3284 13 18 12.3284 18 11.5V10ZM10 7C8.3431 7 7 8.3431 7 10C7 11.6569 8.3431 13 10 13C11.6569 13 13 11.6569 13 10C13 8.3431 11.6569 7 10 7Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 17 17" id="send">
                <path d="M1.29103 6.23733C0.903195 6.10789 0.900137 5.89904 1.299 5.76592L15.4649 1.03795C15.8571 0.907038 16.082 1.12687 15.9722 1.51203L11.9248 15.6959C11.8127 16.0887 11.5866 16.1023 11.4208 15.7289L8.75306 9.71873L13.2063 3.77351L7.26865 8.23243L1.29103 6.23733Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 20 20" id="answer">
                <path d="M18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.6418 18 13.1681 17.5054 14.4381 16.6571L15.5476 18.3214C13.9602 19.3818 12.0523 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10V11.5C20 13.433 18.433 15 16.5 15C15.2958 15 14.2336 14.3918 13.6038 13.4659C12.6942 14.4115 11.4158 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C11.1258 5 12.1647 5.37209 13.0005 6H15V11.5C15 12.3284 15.6716 13 16.5 13C17.3284 13 18 12.3284 18 11.5V10ZM10 7C8.3431 7 7 8.3431 7 10C7 11.6569 8.3431 13 10 13C11.6569 13 13 11.6569 13 10C13 8.3431 11.6569 7 10 7Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 15 16" id="copy">
                <path d="M3.88878 3.5V1.25C3.88878 0.83579 4.21213 0.5 4.61101 0.5H13.2778C13.6767 0.5 14 0.83579 14 1.25V11.75C14 12.1642 13.6767 12.5 13.2778 12.5H11.1111V14.7493C11.1111 15.1639 10.7861 15.5 10.3839 15.5H1.72704C1.32543 15.5 1 15.1665 1 14.7493L1.00188 4.25065C1.00195 3.83608 1.32691 3.5 1.72903 3.5H3.88878ZM5.33324 3.5H11.1111V11H12.5555V2H5.33324V3.5ZM3.88878 7.25V8.75H8.22216V7.25H3.88878ZM3.88878 10.25V11.75H8.22216V10.25H3.88878Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 18 18" id="add-picture">
                <path d="M15.5455 11.6V14H18V15.6H15.5455V18H13.9091V15.6H11.4545V14H13.9091V11.6H15.5455ZM15.5522 2C16.0004 2 16.3636 2.35596 16.3636 2.79472L16.3642 10.2734C15.8522 10.0963 15.3012 10 14.7273 10V3.6H1.63636L1.63718 14.8L9.23965 7.36572C9.53411 7.07683 9.99818 7.05458 10.3192 7.29858L10.3962 7.36618L13.2972 10.2069C11.2835 10.8055 9.81818 12.6354 9.81818 14.8C9.81818 15.3612 9.91669 15.8999 10.0978 16.4006L0.811473 16.4C0.363314 16.4 0 16.0441 0 15.6053V2.79472C0 2.35581 0.372526 2 0.811473 2H15.5522ZM4.90909 5.2C5.81283 5.2 6.54545 5.91634 6.54545 6.8C6.54545 7.68368 5.81283 8.4 4.90909 8.4C4.00535 8.4 3.27273 7.68368 3.27273 6.8C3.27273 5.91634 4.00535 5.2 4.90909 5.2Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 20 20" id="attention">
                <path d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM9 13V15H11V13H9ZM9 5V11H11V5H9Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 20 20" id="money">
                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10.88 15.76V17H9.13V15.71C8.39 15.53 6.74 14.94 6.11 12.75L7.76 12.08C7.82 12.3 8.34 14.17 10.16 14.17C11.09 14.17 12.14 13.69 12.14 12.56C12.14 11.6 11.44 11.1 9.86 10.53C8.76 10.14 6.51 9.5 6.51 7.22C6.51 7.12 6.52 4.82 9.13 4.26V3H10.88V4.24C12.72 4.56 13.39 6.03 13.54 6.47L11.96 7.14C11.85 6.79 11.37 5.8 10.06 5.8C9.36 5.8 8.25 6.17 8.25 7.19C8.25 8.14 9.11 8.5 10.89 9.09C13.29 9.92 13.9 11.14 13.9 12.54C13.9 15.17 11.4 15.67 10.88 15.76Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 22 22" id="money-2">
                <path d="M11 1C5.48 1 1 5.48 1 11C1 16.52 5.48 21 11 21C16.52 21 21 16.52 21 11C21 5.48 16.52 1 11 1ZM11.88 16.76V18H10.13V16.71C9.39 16.53 7.74 15.94 7.11 13.75L8.76 13.08C8.82 13.3 9.34 15.17 11.16 15.17C12.09 15.17 13.14 14.69 13.14 13.56C13.14 12.6 12.44 12.1 10.86 11.53C9.76 11.14 7.51 10.5 7.51 8.22C7.51 8.12 7.52 5.82 10.13 5.26V4H11.88V5.24C13.72 5.56 14.39 7.03 14.54 7.47L12.96 8.14C12.85 7.79 12.37 6.8 11.06 6.8C10.36 6.8 9.25 7.17 9.25 8.19C9.25 9.14 10.11 9.5 11.89 10.09C14.29 10.92 14.9 12.14 14.9 13.54C14.9 16.17 12.4 16.67 11.88 16.76Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 20 20" id="star">
                <path d="M10 16.3625L3.81967 20L5.20001 12.6956L0 7.63932L7.03345 6.76248L10 0L12.9666 6.76248L20 7.63932L14.8001 12.6956L16.1804 20L10 16.3625Z" fill="currentColor"/>
            </symbol>

            <symbol viewBox="0 0 25 25" id="calendar-table">
                <path d="M18.75 2.5H23.75C24.4404 2.5 25 3.05965 25 3.75V23.75C25 24.4404 24.4404 25 23.75 25H1.25C0.55965 25 0 24.4404 0 23.75V3.75C0 3.05965 0.55965 2.5 1.25 2.5H6.25V0H8.75V2.5H16.25V0H18.75V2.5ZM2.5 10V22.5H22.5V10H2.5ZM5 12.5H7.5V15H5V12.5ZM5 17.5H7.5V20H5V17.5ZM10 12.5H20V15H10V12.5ZM10 17.5H16.25V20H10V17.5Z" fill="currentColor"/>
            </symbol>

        </svg>
    )
}
